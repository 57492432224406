import styled from '@emotion/styled';
import { Link, Text } from '~/shared/components';
import { cover } from '~/shared/utils/styled';
import { breakpoints } from '~/theme/breakpoints';
import Button from '~/shared/components/Button';
import { mq } from '~/lib';

export const StyledCategories = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.space[1],

    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(5, minmax(150px, 1fr))',
    },
}));

export const StyledCategory = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.space[3],

    [breakpoints.md]: {
        [`&:hover ${StyledAction}`]: {
            svg: {
                transform: `translateX(${theme.space[2]})`,
            },
        },
    },
}));

export const StyledInfoContainer = styled.div(() => ({}));

export const StyledImageContainer = styled.div(({ theme }) => ({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    marginBottom: theme.space[4],
    [mq(0, 'md')]: {
        marginBottom: theme.space[3],
    },
}));

export const StyledAction = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: theme.fontSizes['2xs'],
    padding: `${theme.space[4]} ${theme.space[1]}`,
    gap: theme.space[2],
    svg: {
        height: '35px',
        width: '35px',
        transition: `transform 250ms ${theme.easings.smoothEase}`,
    },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes['2xs'],
    ...cover,
}));
export const StyledButton = styled(Button)(({ theme }) => ({
    svg: {
        height: '16px',
        width: '16px',
    },

    ':hover': {
        backgroundColor: 'transparent',
    },
    '&:disabled': {
        background: 'transparent',
        color: theme.colors.disabled,
    },
}));

export const Headline = styled(Text)(({ theme }) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    [mq(0, 'sm')]: {
        fontSize: theme.fontSizes['sm'],
        lineHeight: theme.fontSizes['lg'],
    },
    [mq('sm', 'md')]: {
        fontSize: theme.fontSizes['sm'],
        lineHeight: theme.fontSizes['lg'],
    },
}));

export const SubHeadline = styled(Text)(({ theme }) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    '-webkitLineClamp': '3',
    '-webkitBoxOrient': 'vertical',
    whiteSpace: 'normal',
    [mq(0, 'sm')]: {
        fontSize: theme.fontSizes['xs'],
        lineHeight: theme.fontSizes['lg'],
    },
    [mq('sm', 'md')]: {
        fontSize: theme.fontSizes['xs'],
        lineHeight: theme.fontSizes['lg'],
    },
}));
